
import { map, zip, } from 'rxjs/operators';
import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { GeoLocation } from '../../model/providerlist.model';
import { ProviderSearchMethod } from '../../shared/constants';
import { of, BehaviorSubject, Subject } from 'rxjs';
import { AppSettings } from '../../shared/appSettings';
import { Tenant } from '../../shared/constants';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Tenant': Tenant.Name + '/' + Tenant.Id
  })
};

@Injectable()
export class ManualEntryService {
  private baseUrl = `${AppSettings.getEnvironmentVariable('apiEndpoint')}ProviderSearch/`;
  isPreferred: boolean;
  constructor(private http: HttpClient) {
    this.isPreferred = false;
    this.gbpVisibilityChange.subscribe((value) => {
      this.isGBPVisible = value
    });

  }
  geolocations: GeoLocation[];
  searchResult: string[] = [];
  providerSearchResult: string[] = [];
  errorMessage: string;
  searchValue: string;
  gbpResult: any;
  public gbpRecieved = new BehaviorSubject(null);
  isGBPVisible: boolean;
  gbpVisibilityChange: Subject<boolean> = new Subject<boolean>();
  gbpResultData: Subject<any> = new Subject<any>();

  CityState: any;

  search(term: string) {
    term = term.trim();
    if (term === '') {
      return of([]);
    }

    let url = `${this.baseUrl}${ProviderSearchMethod.Geolocations}/${term}/${this.isPreferred}`;
    this.searchResult = [];

    let res = this.http
      .get(url, httpOptions).pipe(
        map(response => response));
    this.CityState = res;
    return res;
  }

  searchProvider(term: string, zipCode: string, carrierId: string, providerType: string) {
    term = term.trim();
    if (term === '') {
      return of([]);
    }
    zipCode = zipCode.replace(',', '');
    let url = `${this.baseUrl}${ProviderSearchMethod.ProviderName}/${zipCode}/${carrierId}/${providerType}/${term}`;
    this.providerSearchResult = [];
    let res = this.http
      .get(url, httpOptions).pipe(
        map(response => response));
    return res;
  }
  toggleGBPVisibilty() {
    this.gbpVisibilityChange.next(!this.isGBPVisible);
  }
  getGBPResult(ZipCode: string, CarrierIds: string, ProviderType: string, ProviderName: string = "", ProviderId: string = ""): any {
    let url = `${this.baseUrl}${ProviderSearchMethod.GroupedByProvider}`;
    const req = this.http.post(url, {
      "PageNumber": 1,
      "ResultsPerPage": 25,
      "ZipCode": ZipCode,
      "CarrierId": CarrierIds,
      "ProviderType": ProviderType,
      "ProviderName": ProviderName,
      "ProviderId": ProviderId
    }, httpOptions)
      .subscribe(
        res => {
          console.log(res);
          this.gbpResultData.next(res);
          this.gbpVisibilityChange.next(true);
        },
        err => {
          this.gbpVisibilityChange.next(false);
        }
      );
  }

}
